import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import HeroChevron from "../../components/hero/hero-chevron";
import AboutUsNavigation from "../../components/about-us/about-us-navigation";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import Icon from "../../components/custom-widgets/icon";
import List from "../../components/custom-widgets/list";

const WaFdFoundation = () => {
  const title = "Washington Federal Foundation";
  const description =
    "The Washington Federal Foundation's purpose is to facilitate direct giving to community-based nonprofits.";

  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/about-us/washington-federal-foundation/hero-washington-federal-foundation-011123-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/about-us/washington-federal-foundation/hero-washington-federal-foundation-011123-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/about-us/washington-federal-foundation/hero-washington-federal-foundation-011123-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/about-us/washington-federal-foundation/hero-washington-federal-foundation-011123-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/about-us/washington-federal-foundation/hero-washington-federal-foundation-011123-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/about-us/washington-federal-foundation/hero-washington-federal-foundation-011123-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/about-us/washington-federal-foundation/hero-washington-federal-foundation-011123-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
      emergencyImage: file(relativePath: { eq: "thumbnail-emergency-05112023-540.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      foodNutritionImage: file(relativePath: { eq: "thumbnail-food-05112023-540.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      economicStabilityImage: file(relativePath: { eq: "thumbnail-economic-stability-05112023-540.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      financialLiteracyImage: file(relativePath: { eq: "thumbnail-financial-literacy-05112023-540.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      employeeinvolvementImage: file(relativePath: { eq: "thumbnail-employee-involvement-05102023-540.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const heroChevronData = {
    id: "open-business-bank-account-online-hero",
    ...getHeroImgVariables(imgData),
    altText: "WaFd employees presenting Building of Hope check.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Investing in Our Communities Since 1917",
            class: "text-white"
          }
        }
      ]
    }
  };

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/washington-federal-foundation"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-washington-federal-foundation-011123.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      active: true,
      title: "Washington Federal Foundation"
    }
  ];

  const cardsData = [
    {
      title: "Emergency and Sustainable Housing",
      image: {
        imgVariable: imgData.emergencyImage.childImageSharp.gatsbyImageData,
        altText: "Habitat for Humanity volunteers building a house."
      },
      components: [
        <p>
          We support organization like Habitat for Humanity, land trusts, and other low-income housing programs that get
          people into stable homes. We also support homeless and emergency shelters and organizations that provide basic
          needs, wrap around services, wellness programs, social services, sustainable senior living, and other housing
          programs that help get people back on their feet and move out of poverty.
        </p>
      ]
    },
    {
      title: "Food and Nutrition",
      image: {
        imgVariable: imgData.foodNutritionImage.childImageSharp.gatsbyImageData,
        altText: "Smiling food distribution volunteers."
      },
      components: [
        <p>
          With the abundance of food in our society today, no one should go hungry. We support organizations that
          deliver food and nutritious meals to those in need.
        </p>
      ]
    },
    {
      title: "Economic Stability and Job Development",
      image: {
        imgVariable: imgData.economicStabilityImage.childImageSharp.gatsbyImageData,
        altText: "Job development trainee working on an assembly project."
      },
      components: [
        <p>
          Economic stability means people have the essentials they need to live a healthy life. Living wage jobs are a
          corner stone in the stability that lifts people out of poverty. We support organizations and programs that
          provide employment training, access to affordable childcare, reliable transportation, access to quality
          healthcare, career counseling, education services, disability services, workforce training, small business
          entrepreneurialism, and employment stability.
        </p>
      ]
    },
    {
      title: "Financial Literacy",
      image: {
        imgVariable: imgData.financialLiteracyImage.childImageSharp.gatsbyImageData,
        altText: "Students raising hands in a classroom."
      },
      components: [
        <p>
          Financial Literacy How to get out of debt, write a check, create a budget, or save for a home. These are all
          important skills everyone needs to build bright and stable financial futures. We give to organizations that
          provide financial literacy education so individuals and families can make empowered and informed financial
          decisions.
        </p>,
        <Link
          to="/about-us/community-financial-education"
          id="financial-literacy-link"
          className="text-decoration-none"
        >
          See more about Financial Literacy
          <Icon name="arrow-right" class="ml-2" />
        </Link>
      ]
    },
    {
      title: "Employee Involvement",
      image: {
        imgVariable: imgData.employeeinvolvementImage.childImageSharp.gatsbyImageData,
        altText: "Volunteers working on a cleanup project."
      },
      components: [
        <p>
          Washington Federal Foundation will consider small grants to charitable organizations where our employees serve
          in a leadership role or as a board member.
        </p>,
        <p className="text-gray-60 text-sm">
          Some Exclusions: While we gladly consider all requests, the Foundation giving focus does not include
          individual scholarships, government agencies, museums or galleries, research into disease prevention or cures,
          sponsorships or contributions to sport teams, fundraising events or special events.
        </p>
      ]
    }
  ];

  const requiredDocumentationListData = {
    accountName: "required-documentation",
    items: [
      {
        id: 1,
        text: "A copy of the IRS letter documenting the 501(c)(3) status of your organization"
      },
      {
        id: 2,
        text: "The mission statement of your organization"
      },
      {
        id: 3,
        text: "A list of your board of directors with their affiliations"
      },
      {
        id: 4,
        text: "A complete budget for the project for which you are requesting funding"
      },
      {
        id: 5,
        text: "The current annual operating budget for your organization"
      },
      {
        id: 6,
        text: "Audited financial statements of the most recent fiscal year end"
      },
      {
        id: 7,
        text: "Your most recent annual report"
      },
      {
        id: 8,
        text: "A copy of your most recently filed Form 990"
      },
      {
        id: 9,
        text: "Optional additive to application: If you have a connection with WaFd   Bank employees who volunteer, service on your Board of Directors or if you have a business connection, please also include that information in your application. We encourage you to request a letter of support that can be sent to Kristi Ellefson (<a href='mailto:kristi.ellefson@wafd.com'>kristi.ellefson@wafd.com</a>) to compliment your application."
      }
    ]
  };

  const otherConsiderationsListData = {
    accountName: "other-considerations",
    items: [
      {
        id: 1,
        text: "You must be a valid 501c3 non-profit in service for at least 12 months"
      },
      {
        id: 2,
        text: "You must be in proximity to a WaFd Bank branch (within a 45-minute drive)"
      },
      {
        id: 3,
        text: "We review grants on a monthly basis. Organizations are able to apply for funding once per calendar year"
      },
      {
        id: 4,
        text: "Please allow 60-90 days for the review process"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <AboutUsNavigation />
      <section className="container">
        <h1 className="text-success">Washington Federal Foundation</h1>
        <div className="row">
          <div className="col-md-8">
            <p>
              The Washington Federal Foundation's purpose is to facilitate direct giving to community-based nonprofits
              that primarily serve the needs of people with low-and-moderate incomes.
            </p>
            <p className="mb-0">
              Grants range from $1,000 - $5,000 and applications are taken on a rolling basis. Organizations can apply
              once per calendar year.
            </p>
          </div>
          <div className="col-md-4 row justify-content-between align-items-center align-self-baseline">
            <div className="col col-lg-12 mb-3 mb-lg-3 mb-sm-0">
              <a
                id="grant-application-documentation-link"
                href="#required-documentation-section"
                className="text-decoration-none"
              >
                Grant application documentation
                <Icon name="arrow-down" class="ml-2" />
              </a>
            </div>
            <div className="col-auto">
              <a
                href="/documents/wafd-bank-wff-grant-app-2022.pdf"
                id="apply-now-grant-application-btn-1"
                target="_blank"
                className="btn btn-primary w-100 w-sm-auto"
              >
                Apply Now
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <h3 className="font-weight-semi-bold text-success">
          We direct our monthly contributions to these primary areas:
        </h3>
        <div className="row">
          {cardsData.map((obj, index) => (
            <div className="col-md-6 mb-4" key={index}>
              <GatsbyImage image={obj.image.imgVariable} alt={obj.image.altText} className="mb-3" />
              <h3 className="font-weight-semi-bold text-success">{obj.title}</h3>
              {obj.components &&
                obj.components.map((component, index) => {
                  return <div key={index}>{component}</div>;
                })}
            </div>
          ))}
        </div>
      </section>
      <section className="bg-light" id="required-documentation-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6  border-md-right">
              <h3 className="font-weight-semi-bold">Apply For Funding - Required Documentation</h3>
              <p>
                Please{" "}
                <a href="/documents/wafd-bank-wff-grant-app-2022.pdf" target="_blank">
                  review the grant application
                </a>{" "}
                and ensure you include the necessary documentation.
              </p>
              <List {...requiredDocumentationListData} />
              <a
                href="/documents/wafd-bank-wff-grant-app-2022.pdf"
                id="apply-now-grant-application-btn-2"
                target="_blank"
                className="btn btn-primary w-100 w-sm-auto"
              >
                Apply Now
              </a>
            </div>
            <div className="col-md-6 mt-4 mt-md-0">
              <h3 className="font-weight-semi-bold">Other Considerations</h3>
              <List {...otherConsiderationsListData} />
              <p>
                For more information about our community giving program, please call{" "}
                <a href="tel:206-654-9356">206-654-9356</a> and ask for Community Relations.
              </p>
              <p className="text-sm text-gray-60">
                Some Exclusions: While we gladly consider all requests, the Foundation giving focus does not include
                individual scholarships, government agencies, the arts, research into disease prevention, secondary
                education, or contributions to sport teams, programs or events.
              </p>
            </div>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default WaFdFoundation;
